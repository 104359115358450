
.gray-icon {
	color: $secondary;
	margin-right: 4px;
}

.navbar-toggler {
	background-color: $white;
	border-radius: 15px;
    padding: 14px;
}

.navbar-dark .navbar-toggler {
    color: black;
}

.fa-bars {
	margin-right: 2px;
}


.breadcrumb {
	background-color: transparent;
}
.breadcrumb-item + .breadcrumb-item::before {
    color: $primary;
    content: "|";
}


.bg-lightgray, .white-stripe-food {
	/* Fallback for web browsers that doesn't support RGBa */
	background: $primary-light;

	/* RGBa with 0.6 opacity */
	background: rgba(169, 169, 169, 0.6);
}


.bg-mountains {
    background-image: url("/images/footer-background.png");
    background-repeat: repeat-x;
    background-attachment: fixed;
    background-position: bottom;
}

.SideBars {
	border-radius: 15px;
    border: 2px solid $primary;
    padding: 16px;
    margin-bottom: 20px;
}

.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.6) !important;
}

//HomePage Image Overlay

.logo-stripe {
	position: absolute;
	bottom: 40%;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.6);
	overflow: hidden;
	width: 100%;
	z-index: 2;
}


// Backgrounds
	@include media-breakpoint-up(xl) { 
		.logo-stripe {
			bottom: 45%;
			} 
	}


.ImageContainer {
	position: relative;
	width: 100%;
}

.HomeImage {
	display: block;
	width: 100%;
	height: auto;
	-webkit-border-radius: 13px 13px 0 0;
	border-radius: 13px 13px 0 0;
}

.overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0);
	overflow: hidden;
	width: 100%;
	height: 100%;
	transition: .5s ease;
	-webkit-border-radius: 13px 13px 0 0;
	border-radius: 13px 13px 0 0;
}

.ImageContainer:hover .overlay {
	background: rgba(255, 255, 255, 0.6);
}



.btn-home {
	background: rgba(255, 255, 255, 0.8);
	font-size: 2em;
	position: absolute;
	bottom: 4%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	font-weight: 800;
	border: 2px solid $secondary;
	left: 50%;
	width: 80%;
}

		@include media-breakpoint-up(lg) {
		  .btn-home {
			font-size: 1.4em;
		}
		}


		@include media-breakpoint-up(xl) {
		  .btn-home {
			font-size: 1.8em;
			width: 90%;
		}
		}

		@include media-breakpoint-up(xxl) {
		  .btn-home {
			width: 60%;
		}
		}

.chevron-small-btn {
	margin-left: 5px;
}


.btn-secondary {
	color: #fff !important;
	text-decoration: none;
}

@include media-breakpoint-down(md) {
  .btn-block-md-below {
    display: block;
    width: 100%;
  }
}




.HomePageBox {
	padding: 0;
	margin: 0;
	-webkit-border-radius: 15px 15px 15px 15px;
	border-radius: 15px 15px 15px 15px;
	border: 2px $primary solid;
	background: white;
	height: 100%;
}


.HomePageList {
	list-style: none;
	line-height: 2em;
	margin-left: -40px;
	margin-top: 10px;
}



.ChaletFeatures {
	list-style: none;
	line-height: 2em;
	margin-left: -40px;
	margin-top: 10px;
}






//
// Social
// -----------------------------------------------

.btn-social {

	padding: $social-btn-padding-y $social-btn-padding-x;
	line-height: $social-btn-line-height;
	
}

.btn-facebook {
	color: $facebook;

	&:hover {
		color: $facebook-dark;
	}

}

.btn-twitter {
	color: $twitter;

		&:hover {
		color: $twitter-dark;
	}
}

.btn-google {
	color: $google;

		&:hover {
		color: $google-dark;
	}
}

.btn-instagram {
	color: $instagram;

		&:hover {
		color: $instagram-dark;
	}
}

.btn-linkedin {
	color: $linkedin;

		&:hover {
		color: $linkedin-dark;
	}
}

.btn-pinterest {
	color: $pinterest;

		&:hover {
		color: $pinterest-dark;
	}
}

.btn-youtube {
	color: $youtube; 

		&:hover {
		color: $youtube-dark;
	}
}


.chaletgallery {}

/* fluid 3 columns */

.grid-item h5 {
  font-size: 1.1em;
}



.grid-sizer,
.grid-item { 
    width: 100%;
    margin-bottom: 10px;
    text-align: center;

  }



/* Pages */


ul li {
	margin-bottom: 10px;
}


ul.numbers {
	list-style-type: none;
}





// Backgrounds
	@include media-breakpoint-up(lg) { 
	.navbar {
		background-image: url("/images/header-background.png");
		background-repeat: no-repeat;
		background-position: right; 
	} 
	}



@media (min-width: 1000px) {

/* fluid 3 columns */

.grid-sizer,
.grid-item { 
 	width: 33.333%;
  }
}

.white-stripe-food {
	margin-bottom: 18px;
/* 	background-color: same as bg-lightgray section */
	padding: 16px 20px 16px 20px;
}

.fancy-image {
	border: 1px $secondary solid;
	padding: 24px 22px 24px 22px;
}




