// --------------------------------------------------
//
// Fonts
//
// --------------------------------------------------

// Font Stacks

$font-url-google		:	'https://fonts.googleapis.com/css?family=PT+Mono|Lato';


$font-family-base		:		'Lato', sans-serif;
$headings-font-family  	:		'PT Mono', monospace;


$font-family-sans-serif:     'Lato', sans-serif;
$font-family-monospace:      'PT Mono', monospace;

//==== Fonts

$base__font-size		:		18px;
$base__line-height		:		40px;



// CSS

body {
	font-family: $font-family-base;
	font-size: $base__font-size;
}

.navbar-nav {
	font-family: $headings-font-family;
	letter-spacing: -1px;
	font-weight: bold;
}

a.nav-link {
	color: $primary-light !important;
	text-decoration: none;
		&:hover {
		color: $secondary !important;
	}
  }



p a, ul a {
	color: $secondary !important;
	text-decoration: underline;
		&:hover {
		color: $secondary !important;
	}
  }


h1, h2, h3, h4 {
	font-family: $font-family-monospace;
}

h1 {
	font-size: 1.6em;
	color: $secondary;
	letter-spacing: -1px;
}

h2 {
	color: $primary;
	font-size: 1.4em;
	letter-spacing: -1px;
	font-weight: bold;
	line-height: $base__line-height;
}



h3 {
	color: $primary;
	font-size: 1em;
	letter-spacing: 0.5px;
	font-weight: bold;
}

.SummerSun {

	color: $summersun;
		&:hover {
			color: $primary-light;
		}
}

.WinterBlue {

	color: $winterblue;		
		&:hover {
			color: $primary-light;
		}
}


.navigation-label {
	font-size: 1.4rem;
}

.dropdown-item {
	background-color: white;
	transition: background-color 0.6s ease-out;
	font-size: $base__font-size;
	padding: 10px;
}

a.dropdown-item  {
	text-decoration: none;
}


.dropdown-item:hover {
	background-color: $primary-light;
	transition: background-color 0.6s ease-in;
}


	@include media-breakpoint-up(lg) { 

		body {
			font-size: $base__font-size/1.1;
		}


		.dropdown-item {
			font-size: $base__font-size/1.2;
			padding: 6px 8px;
		}
	}