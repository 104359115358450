// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

/* set the overriding variables */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1800px
);

$container-max-widths: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1800px
);
 

$redberry: 			#961700;
$shuttlegray:		#505050;
$summersun	:		#FDB813;
$winterblue	:		#7ba5f8;

$primary:       $shuttlegray;
$secondary:     $redberry;

$primary-light		: 		lighten($primary, 50%);
$primary-dark		: 		darken($primary, 25%);

$secondary-light	: 		secondary($primary, 50%);
$secondary-dark		: 		secondary($primary, 25%);


$body-bg:       white;


//== Social Colors

$facebook			:		#3b5998;
$facebook-dark		: 		darken($facebook, 25%);
$twitter			:		#1DA1F2;
$twitter-dark		: 		darken($twitter, 25%);
$google				:		#DB4437;
$google-dark		: 		darken($google, 25%);
$instagram			:		#6dc993;
$instagram-dark		: 		darken($instagram, 25%);
$linkedin			:		#0077B5;
$linkedin-dark		: 		darken($linkedin, 25%);
$pinterest			:		#BD081C;
$pinterest-dark		: 		darken($pinterest, 25%);
$youtube			:		#282828;
$youtube-dark		: 		darken($pinterest, 25%);

$social-btn-padding-y:         .2rem;
$social-btn-padding-x:         .5rem;
$social-btn-line-height:       .9rem;

